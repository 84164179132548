import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { LuMoreHorizontal } from 'react-icons/lu';
import { SITELINK } from '../../../app-routes/Links';
import { generateRandomHexColor, getFirstLetters, getUser, truncateText } from '../../../app-utils';
import { Verify } from '../../../assets/custom-icons';
import LazyLoad from 'react-lazyload';
import { useLikeFeedById } from '../../../api-services/feeds/useLikeFeedById';
import { PiShareFatDuotone } from 'react-icons/pi';
import { TbMessageCircle } from 'react-icons/tb';
import Modal from '../../../components/modal';
import { useSaveFeedById } from '../../../api-services/feeds/useSaveFeedById';
import { UserInterface } from '../../../app-schemas/profile.schema';
import { useDeleteFeedById } from '../../../api-services/feeds/useDeleteFeeds';
import { PostInterface } from '../../../app-schemas/posts/post.schema';

interface PostCardProps {
  post: PostInterface;
  index: number;
  innerRef?: React.Ref<HTMLDivElement>;
}

const PostCard: React.FC<PostCardProps> = ({ post, index, innerRef }) => {
  const likeFeedById = useLikeFeedById();
  const saveFeedById = useSaveFeedById();
  const deleteFeedById = useDeleteFeedById();
  const user: UserInterface = getUser();

  const [openSetupModal, setOpenSetupModal] = useState<boolean>(false);

  const handleOpenSetup = () => {
    openSetupModal
      ? document.body.classList.remove('overflow-hidden')
      : document.body.classList.add('overflow-hidden');
    setOpenSetupModal(!openSetupModal);
  };

  const handleToggleSetupModal = useCallback(() => {
    document.body.classList.toggle('overflow-hidden', !openSetupModal);
    setOpenSetupModal((prev) => !prev);
  }, [openSetupModal]);

  const handleLikeFeedById = useCallback(() => {
    likeFeedById.mutate(post.post_id);
  }, [likeFeedById, post.post_id]);

  const handleSaveFeed = useCallback(() => {
    saveFeedById.mutate(post.post_id);
    handleToggleSetupModal();
  }, [saveFeedById, post.post_id, handleToggleSetupModal]);

  const handleDeleteFeed = useCallback(() => {
    deleteFeedById.mutate(post.post_id);
    handleToggleSetupModal();
  }, [deleteFeedById, post.post_id, handleToggleSetupModal]);

  const handleShareFeed = useCallback(() => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Catapot',
          text: 'my post from catapot.com',
          url: 'catapot.com',
        })
        .then(() => console.log('Share successful'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Web Share API is not supported in this browser.');
    }
  }, []);

  const truncatedContent = useMemo(() => truncateText(post?.content ?? '', 135), [post?.content]);

  const profileImage = post?.creator?.profile_picture_url ? (
    <LazyLoad height={200} offset={1000} once>
      <img
        alt="profile"
        src={post?.creator?.profile_picture_url}
        className="object-cover rounded-full w-[27px] h-[27px] bg-gray-200 shadow-sm"
      />
    </LazyLoad>
  ) : (
    <span
      className="block w-[27px] h-[27px] rounded-full border border-green-100"
      style={{ background: generateRandomHexColor() }}
    >
      <h3 className="text-white uppercase text-[1rem] font-[500] relative top-[1px] left-1.5">
        {getFirstLetters(post?.creator?.username)}
      </h3>
    </span>
  );

  const renderImages = useMemo(
    () =>
      post?.images?.map((image, idx) => (
        <LazyLoad height={200} offset={1000} once key={idx}>
          <div
            className="absolute inset-0 bg-cover bg-center blur-3xl"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <img src={image} className="relative w-full z-10" alt={`post_image ${idx}`} />
        </LazyLoad>
      )),
    [post?.images],
  );

  return (
    <div key={index} className="bg-white mt-2 border border-gray-300 shadow-sm" ref={innerRef}>
      <div className="flex gap-2 justify-between p-3 pb-0">
        {/* name & community caption area */}
        <div className="flex gap-2">
          <div>
            <Link to={`${SITELINK.MY_PROFILE}/${post?.creator?.username}`}>{profileImage}</Link>
          </div>

          <div>
            <div className="text-xs text-gray-800 font-semibold flex gap-1">
              <span>{post?.creator?.username ? post?.creator?.username : 'Anonymous'}</span>
              <span>
                {post?.creator?.is_verified && <img src={Verify} alt="" className="h-[12px]" />}
              </span>
            </div>

            <div className="flex gap-2">
              <Link
                to={`${SITELINK.COMMUNITIES}/${post?.community}`}
                className="text-[8px] text-gray-500"
              >
                {post?.community}
              </Link>
              <div className="text-[8px] text-gray-400">{post?.created_on}</div>
            </div>
          </div>
        </div>

        <div>
          <div className="className='flex justify-end w-full">
            <button className="flex justify-center w-full" onClick={handleOpenSetup}>
              <LuMoreHorizontal className="text-xl text-gray-800" />
            </button>
          </div>

          {post?.is_boosted && (
            <button className="flex justify-end rounded-lg bg-white border border-gray-300 font-bold text-gray-500 px-1 border text-[8px]">
              Sponsored
            </button>
          )}
        </div>
      </div>

      {/* Content Section */}
      <div className="text-[0.9rem] px-3 mb-3 text-black font-[400] mt-2">
        <div className="font-medium">
          {truncatedContent}

          {post?.content?.length > 134 ? (
            <Link to={`${SITELINK.VIEW_POSTS}/${post.post_id}`} className="text-gray-600 text-xs">
              See more
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* Media Section */}
      {post?.audio && (
        <audio controls src={post?.audio} className="w-full custom-audio-player px-3 pb-3"></audio>
      )}
      {post?.images?.length > 0 && (
        <div className="relative flex justify-center items-center overflow-hidden bg-cover bg-center max-h-[300px]">
          {renderImages}
        </div>
      )}

      {/* Action Section */}
      <div className="px-3 pb-3 border-t border-gray-50">
        <div className="flex justify-between gap-2 mt-2 font-medium text-[12px]">
          <button
            className={`flex items-center bg-gray-100 rounded-full px-3 py-2 ${
              post?.reacted_to_post ? 'text-custom-600 ' : ''
            }`}
            onClick={handleLikeFeedById}
          >
            <div className="like-button">
              <div className="heart-bg">
                <div className={`heart-icon ${post?.reacted_to_post && 'liked'}`}></div>
              </div>
            </div>
            {/* figure round-up to K, M, B... */}
            <span className="pr-1">{post?.reactions > 0 ? post?.reactions : ''}</span> Like
            {post?.reactions > 1 && 's'}
          </button>

          {/* comment button */}
          <Link
            to={`${SITELINK.VIEW_POSTS}/${post.post_id}`}
            className="flex items-center bg-gray-100 rounded-full px-3 py-2"
          >
            <TbMessageCircle className="text-[1.1rem] text-gray-500 mr-1" />
            {post?.comments > 1 && <span className="pl-1 pr-1">{post?.comments}</span>} Comment
            {post?.comments > 1 && 's'}
          </Link>

          {/* share button */}
          <button
            className="flex items-center bg-gray-100 rounded-full px-3 py-2"
            onClick={handleShareFeed}
          >
            <PiShareFatDuotone className="text-[1.1rem] text-gray-500 mr-1" />
            Share
          </button>
        </div>
      </div>

      {/* other options button */}
      <Modal isOpen={openSetupModal} onClose={handleOpenSetup}>
        <div className="text-center font-medium text-sm w-[80vw] shadow-2xl">
          <button className="border-b px-8 py-4 block w-full">Copy link</button>
          <button className="border-b px-8 py-4 block w-full">Repost post</button>

          <button className="border-b px-8 py-4 w-full" onClick={handleSaveFeed}>
            {post?.bookmarked_post ? 'Remove from likes' : 'Add to likes'}
          </button>

          {post.creator.email === user.email && (
            <button className="border-b px-8 py-4 w-full" onClick={handleDeleteFeed}>
              Delete post
            </button>
          )}

          <Link to={`${SITELINK.VIEW_POSTS}/${post.post_id}`} className="border-b px-8 py-4 w-full block">
            View post
          </Link>

          <button className="border-b px-8 py-4 w-full" onClick={handleShareFeed}>
            Share post
          </button>

          <button
            className="text-red-600 font-semibold border-b px-8 py-4 block w-full rounded-b-2xl"
            onClick={handleToggleSetupModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PostCard;
