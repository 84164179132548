import { useEffect, useRef, useState } from 'react';
import { LuArrowLeft, LuMoreHorizontal } from 'react-icons/lu';
import { PiShareFatDuotone } from 'react-icons/pi';
import { TbMessageCircle } from 'react-icons/tb';
import LazyLoad from 'react-lazyload';
import { Link, useParams } from 'react-router-dom';
import { getPostById } from '../../api-services/feeds/useGetFeedById';
import { SITELINK } from '../../app-routes/Links';
import { generateRandomHexColor, getFirstLetters } from '../../app-utils';
import { Verify } from '../../assets/custom-icons';
import { VscSend } from 'react-icons/vsc';
import { useFeedComments } from '../../api-services/feeds/useGetCommentsByFeedId';
import { usePostComment } from '../../api-services/feeds/useCommentByFeedId';

// interface Post {
//   audio?: string;
//   bookmarked_post: boolean;
//   comments: number;
//   community: string;
//   content: string;
//   created_on: string;
//   creator: {
//     email: string;
//     first_name: string;
//     is_verified: boolean;
//     last_name: string;
//     username: string;
//     profile_picture_url: string;
//   };
//   images: string[];
//   is_boosted: boolean;
//   post_id: number;
//   reacted_to_post: boolean;
//   reactions: number;
// }

// main-component-ui
const ViewPost = () => {
  document.body.classList.remove('overflow-hidden');
  const { id } = useParams();
  const [post, setPost] = useState<any>({});
  const [commentMssg, setComment] = useState<any>('');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const comments = useFeedComments();
  const post_comment = usePostComment();

  // Adjust textarea height based on content
  useEffect(() => {
    const textarea = textareaRef.current;
    if (!!textarea) {
      textarea.style.height = 'auto'; // Reset the height to recalculate
      textarea.style.height = textarea.scrollHeight + 4 + 'px'; // Set height based on scrollHeight
    }
  }, [commentMssg]);

  useEffect(() => {
    if (id) {
      getThisPost(id);
      comments.mutate(Number(id));
    }
    // eslint-disable-next-line
  }, [id]);

  const getThisPost = async (id: any) => {
    const feed_info = await getPostById(Number(id));
    setPost(feed_info?.data);
    console.log(feed_info.data);
  };

  const sendComment = async () => {
          setComment('')
    await post_comment.mutate(
      { id: Number(id), commentMssg },
      {
        // This callback will run if the mutation is successful
        onSuccess: () => {
          // Call comments.mutate after the post_comment mutation is successful
          comments.mutate(Number(id));
        },
        // Optionally, handle any error if needed
        onError: (error) => {
          console.error('Error posting comment:', error);
        },
      },
    );
  };

  return (
    <section className="m-0 p-0">
      {/* top nav area */}
      <div className="fixed top-0 right-0 left-0 w-full z-50">
        <div className="flex gap-4 relative px-2 py-3 bg-custom-600 text-white">
          <Link to={SITELINK.HOME} className="font-semibold text-sm flex">
            <LuArrowLeft className="text-2xl" />
          </Link>
          <p className="text-md font-medium">Post</p>
        </div>
      </div>

      {/* content area */}
      <div className="bg-white mt-12 mb-15 border border-gray-300 shadow-sm">
        {/* creator name & community caption area &&  isBoosted*/}
        <div className="flex gap-2 justify-between p-3 pb-0">
          <div className="flex gap-2">
            <div>
              <Link to={`${SITELINK.MY_PROFILE}/${post?.creator?.username}`}>
                {/* profile pix area */}
                {post?.creator?.profile_picture_url ? (
                  <div className="flex-grow flex flex-col items-center">
                    <div className="overflow-hidden flex justify-center">
                      <LazyLoad height={200} offset={1000} once>
                        <img
                          alt="profile"
                          src={post?.creator?.profile_picture_url}
                          className="object-cover rounded-full w-[27px] h-[27px] bg-gray-200 shadow-sm overflow-hidden"
                        />
                      </LazyLoad>
                    </div>
                  </div>
                ) : (
                  <span
                    className={`block w-[27px] h-[27px] rounded-full border border-green-100`}
                    style={{ background: generateRandomHexColor() }}
                  >
                    <h3 className="text-white uppercase text-[1rem] font-[500] relative top-[1px] left-1.5">
                      {getFirstLetters(post?.creator?.username ?? 'C')}
                    </h3>
                  </span>
                )}
              </Link>
            </div>

            {/* creator username view area */}
            <div>
              <p className="text-xs text-gray-800 font-semibold flex gap-1">
                <span>{post?.creator?.username ? post?.creator?.username : 'Anonymous'}</span>
                <span>
                  {post?.creator?.is_verified && <img src={Verify} alt="" className="h-[12px]" />}
                </span>
              </p>

              {/* community link area */}
              <div className="flex gap-2 relative">
                <p className="text-[8px] text-gray-500 font-medium mb-0">
                  <Link to={`${SITELINK.COMMUNITIES}/${post?.community}`}>{post?.community}</Link>
                </p>

                <div className="relative">
                  <p className="text-gray-500 top-[-9px] absolute">.</p>
                </div>
                <p className="text-[8px] text-gray-400 font-medium mb-0 ml-1">{post?.created_on}</p>
              </div>
            </div>
          </div>

          {/* isBoosted ads area */}
          <div>
            <div className="className='flex justify-end w-full hidden">
              <button className="flex justify-center w-full">
                <LuMoreHorizontal className="text-xl text-gray-800" />
              </button>
            </div>

            {post?.is_boosted && (
              <button className="flex justify-end rounded-lg bg-white border border-gray-300 font-bold text-gray-500 px-1 border text-[8px]">
                Sponsored
              </button>
            )}
          </div>
        </div>

        {/* feed article */}
        <div className="px-3 mb-3 mt-3">
          <p className="text-[0.9rem] text-black font-medium">{post?.content ?? ''}</p>
        </div>

        {/* audio area */}
        {post?.audio && (
          <div className="px-3 w-full pb-3">
            <audio controls src={post?.audio} className="w-full custom-audio-player"></audio>
          </div>
        )}

        {/* image caption area */}
        {post?.images?.length > 0 && (
          <div
            className={`relative flex justify-center items-center overflow-hidden bg-cover bg-center`}
            style={{
              gridTemplateColumns: `repeat(${Math.min(post?.images?.length, 4)}, minmax(0, 1fr))`,
            }}
          >
            {post?.images?.map((image: string, index: number) => (
              <LazyLoad height={200} offset={1000} once key={index}>
                <div
                  className="absolute inset-0 bg-cover bg-center blur-3xl"
                  style={{ backgroundImage: `url(${image})` }}
                ></div>
                <img src={image} className="relative w-full z-10" alt={`post_image ${index}`} />
              </LazyLoad>
            ))}
          </div>
        )}

        <div className="px-3 pb-3 border-t border-gray-50 mb-24">
          {/* action buttons */}
          <div className="flex justify-between gap-2 mt-2 font-medium text-[12px]">
            {/* like button */}
            <button
              className={`flex items-center bg-gray-100 rounded-full px-3 py-2 ${
                post?.reacted_to_post ? 'text-custom-600 ' : ''
              }`}
            >
              <div className="like-button">
                <div className="heart-bg">
                  <div className={`heart-icon ${post?.reacted_to_post && 'liked'}`}></div>
                </div>
              </div>

              {/* figure round-up to K, M, B... */}
              {post?.reactions > 0 && <span className="pr-1">{post?.reactions}</span>}
              <span className="pr-1">Like{post?.reactions > 1 ? 's' : ''}</span>
            </button>

            {/* comment button */}
            <Link
              to={SITELINK.VIEW_POSTS + '/' + post.post_id}
              className={`flex items-center bg-gray-100 rounded-full px-3 py-2`}
            >
              <TbMessageCircle className="text-[1.1rem] text-gray-500" />

              {/* figure round-up to K, M, B... */}
              <span className="pr-1">{post?.comments > 1 && post?.comments}</span>
              <span className="pr-1">Comment{post?.comments > 1 ? 's' : ''}</span>
            </Link>

            {/* share button */}
            <button className={`flex items-center bg-gray-100 rounded-full px-3 py-2`}>
              <PiShareFatDuotone className="text-[1.1rem] text-gray-500" />

              {/* figure round-up to K, M, B... */}
              <span className="pr-1">Share</span>
            </button>
          </div>
        </div>

        <div className="comment-section p-4 bg-gray-100 rounded-lg mb-[70px]">
          {comments?.data?.data?.length ? (
            comments.data.data.map((item: any, index: number) => (
              <div
                key={index}
                className="comment-item p-3 mb-4 bg-white shadow-sm rounded-lg flex items-start gap-3"
              >
                {/* User avatar */}
                {/* <div className="avatar">
                  {item?.user?.avatar ? (
                    <img src={item?.user?.avatar} alt="Avatar" className="w-10 h-10 rounded-full" />
                  ) : (
                    <div className="w-10 h-10 bg-gray-300 rounded-full flex justify-center items-center">
                      <span className="text-sm font-semibold text-white">
                        {item?.user?.username.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                </div> */}

                {/* Comment content */}
                <div className="comment-content">
                  {/* Username */}
                  {/* <p className="text-sm font-semibold">{item.user.username}</p> */}

                  {/* Comment text */}
                  <p className="text-gray-800 text-sm">{item?.comment}</p>

                  {/* Creation date */}
                  <p className="text-xs text-gray-500 mt-1">{item?.created}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No comments available.</p>
          )}
        </div>
      </div>

      {/* write a comment area */}
      <div className="bg-white shadow-2xl w-full border-t z-50 px-3 pb-3 border-gray-200 fixed bottom-0">
        {/* action buttons */}
        <div className="flex justify-center mt-3 font-medium text-[12px]">
          <div className="relative w-full">
            <textarea
              value={commentMssg}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              placeholder="write a comment"
              className="text-sm border rounded-3xl p-3 pr-24 w-full"
              style={{
                width: '100%',
                minHeight: '44px',
                maxHeight: '200px',
                overflow: 'hidden',
                resize: 'none',
              }}
              rows={1}
              ref={textareaRef}
            ></textarea>

            <button
              onClick={sendComment}
              className="text-3xl absolute text-custom-600 text-semibold top-[7px] right-[10px]"
            >
              <VscSend />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewPost;
